.authWrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.formWrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  max-width: 500px;
  padding: 25px 15px;
  box-shadow: 0 0 2px gray;
  margin-top: 10px;
  border-radius: 3px;
  min-width: 250px;
  width: 100%;
  max-width: 500px;
}
.Buttons {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.Buttons:first-child {
  margin-right: 10px;
}
.Right {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
