.Select {
  width: 100%;
  margin: 15px 0 0;
  min-height: 25px;
  height: 25px;
  border-radius: 3px;
}
.Select:focus {
  outline: none;
}
