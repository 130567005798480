.mainWrapper {
  width: 100%;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 100vw;
}
.secondaryWrapper {
  background-color: #f8f8f8;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.secondaryWrapper::-webkit-scrollbar {
  height: 5px;
}
.buttonWrapper {
  position: relative;
}
.button {
  position: relative;
  width: 40px;
  height: 44px;
  min-width: 40px;
  min-height: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 3px 5px;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}
.buttonSelected svg,
.buttonSelected p {
  color: rgb(221, 104, 104);
}
.button svg {
  z-index: 10;
  color: rgb(45, 122, 247);
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  margin-bottom: 2px;
}
.buttonName {
  z-index: 10;
  font-size: 12px;
  color: rgb(45, 122, 247);
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}
.Arrow {
  font-size: 19px;
  text-decoration: none;
}
.button:hover .buttonName {
  text-decoration: underline;
}
.dropMenu {
  background-color: white;
  position: absolute;
  top: 0;
  left: -35px;
  z-index: 26;
  max-width: 180px;
  padding: 10px 0;
  box-shadow: 0 0 2px grey;
  border-radius: 2px;
  opacity: 0;
  transition: 0.3s ease;
  user-select: none;
  pointer-events: none;
}
.dropMenuElement {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  padding: 15px;
  cursor: pointer;
  width: 100%;
}
.dropMenuElement:hover {
  background-color: rgb(234, 234, 234);
}
.dropMenuElement svg {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  margin-right: 3px;
  color: #393333;
}
.dropMenuText {
  color: #393333;
  height: 18px;
  overflow: hidden;
  font-size: 16px;
  min-width: 125px;
}
.Show {
  opacity: 1;
  top: 55px;
  pointer-events: all;
}
.Active p,
.Active svg {
  text-decoration: underline;
  color: rgb(33, 60, 157);
}
.Selected p,
.Selected svg {
  text-decoration: underline;
  color: rgb(39, 58, 129);
}
.Selected::before {
  position: absolute;
  content: "";
  width: 75px;
  height: 64px;
  background-color: #dedede;
  z-index: 5;
}
.SelectedDrop,
.SelectedDrop p,
.SelectedDrop svg {
  background-color: #c4c4c4;
}
.SelectedDrop:hover {
  background-color: #c4c4c4;
}
.ExitModalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.Question {
  max-width: 200px;
  text-align: center;
  margin-bottom: 10px;
}
.ExitModalButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.mobileWrapper {
  display: none;
}
.menuButton {
  position: relative;
  background-color: rgb(45, 122, 247);
  width: 45px;
  height: 45px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}
.menuButton span {
  width: 25px;
  height: 4px;
  background-color: #f8f8f8;
  transition: 0.3s ease;
}
.menuButton::after {
  content: "";
  width: 25px;
  height: 4px;
  background-color: #f8f8f8;
  position: absolute;
  top: 12px;
  left: 10px;
}
.menuButton::before {
  content: "";
  width: 25px;
  height: 4px;
  background-color: #f8f8f8;
  position: absolute;
  top: 29px;
  left: 10px;
}
.menuButtonActive {
  background-color: rgb(153, 153, 153);
}
.menuButton:active > span {
  background-color: rgb(221, 104, 104);
}
.mobileTitle {
  z-index: 15;
}
@media only screen and (max-width: 880px) {
  .secondaryWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    justify-items: center;
    padding: 15px 0;
  }
  .Logo {
    display: none;
  }
  .mobileWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    background-color: #f8f8f8;
    padding: 0 5px;
  }
  .secondaryWrapper {
    pointer-events: none;
    opacity: 0;
  }
  .mobileMenuActive {
    pointer-events: all;
    opacity: 1;
  }
  .mainWrapperShrinker {
    max-height: 53px;
    justify-content: flex-start;
  }
}
