.Options {
  width: 100%;
  min-height: 40px;
  background-color: #dedede;
  max-width: 1200px;
  border-bottom: 2px solid #f8f8f8;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.OptionsButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
}
.OptionsButton {
  user-select: none;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  margin: 0 5px;
  color: rgb(41, 116, 208);
  background-color: #f8f8f8;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid rgb(41, 116, 208);
  -webkit-tap-highlight-color: transparent;
}
.OptionsButton:hover {
  background-color: rgb(217, 217, 217);
}
.OptionsButton svg {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.NewGoodWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  background-color: #f8f8f8;
}
.firstHalf {
  height: 100vh;
  width: 50%;
  padding: 15px 10px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.secondHalf {
  height: 100vh;
  width: 50%;
}
.firstHalf > * {
  margin: 16px 0;
}
.SelectWrapper {
  width: 100%;
  user-select: none;
}
.SelectTitle {
  font-size: 13px;
  width: 100%;
  margin-left: 10px;
  color: rgb(156, 156, 156);
  font-weight: 500;
}
.Select {
  width: 100%;
  min-height: 40px;
  border: 2px solid rgb(156, 156, 156);
  border-radius: 3px;
  padding: 5px;
  background-color: transparent;
}
.Select:focus {
  outline: none;
}
.Cake {
  width: 100%;
  height: 500px;
}
@media only screen and (max-width: 880px) {
  .firstHalf {
    width: 100%;
  }
  .secondHalf {
    display: none;
  }
}
