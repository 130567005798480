.AlertWrapper {
  position: fixed;
  background-color: transparent;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  z-index: 100;
}
.Alert {
  padding: 3px 8px;
  border-radius: 4px;
  border: 1px solid rgb(151, 45, 3);
  color: rgb(151, 45, 3);
  background-color: rgb(255, 222, 9);
  margin-top: 20vh;
  max-width: 300px;
}
.Title {
  text-align: center;
}
.Text {
  font-size: 0.8rem;
}
