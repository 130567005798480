.Main {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.NumPadWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  height: 100%;
  max-height: 250px;
  max-width: 250px;
  row-gap: 5px;
  column-gap: 5px;
  user-select: none;
}
.NumPadWrapper > * {
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: none;
  font-size: 15px;
  font-weight: bold;
}
.NumPadItem:active {
  background-color: #bbbbbb;
}
.NumPadItemBackSpace {
  grid-column: 4/4;
  grid-row: 1/3;
}
.NumPadItemEnter {
  grid-column: 4/4;
  grid-row: 3/5;
}
.NumPadItem0 {
  grid-column: 1/3;
}
